import React from 'react';
import './App.css'
import {Carousel, ConfigProvider} from 'antd';
import photo1 from './assets/photo1.jpg'
import photo2 from './assets/photo2.jpg'
import photo3 from './assets/photo3.jpg'
import photo4 from './assets/photo4.jpeg'
import photo5 from './assets/photo5.jpg'

const App = () => (
    <div className="container">
        <div className="title">
            <div className="tvTitle">面包轨迹</div>
            <div className="svg">
                <svg width="400" height="100">

                    <path d="M 50,50 C 100,0 150,100 200,50 C 250,100 300,0 350,50"
                          fill="none" stroke="red" strokeWidth="3"/>
                </svg>
            </div>
        </div>
        <div className="carousel">
            <ConfigProvider
                theme={{
                    components: {
                        Carousel: {
                            dotHeight: 15,
                            dotWidth: 15,
                            dotActiveWidth: 18,
                        },
                    },
                }}
            >
                <Carousel autoplay>
                    <img src={photo1} alt={"photo1"}/>
                    <img src={photo2} alt={"photo2"}/>
                    <img src={photo3} alt={"photo3"}/>
                    <img src={photo4} alt={"photo4"}/>
                    <img src={photo5} alt={"photo5"}/>
                </Carousel>
            </ConfigProvider>
        </div>
        <div className="footer">
            <a href="https://beian.miit.gov.cn/" target="_blank" rel="noreferrer">黑ICP备2023016170号-1</a>
        </div>
    </div>
);
export default App;